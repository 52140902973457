<template>
	<div>
		<el-container>
			<el-header>
				<div>
					<div class="block-header review-header">
						<div style="font-size: 25px;font-weight: 500;margin-bottom: 10px;">顾客评价</div>
						<div style="color: #999;">
							及时回复，提升客户满意度
						</div>
					</div>
				</div>
			</el-header>
			<el-main>
				<div>
					<el-form ref="form" :model="form" label-width="80px">
						<el-form-item label="评价门店">
							<el-select size="small" @change="onchange" v-model="shopid" placeholder="请选择店铺">
								<el-option v-for="(item,index) in shoplist" :key="index" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="评价来源">
							<el-radio-group @input="onchangeoption" size="small" v-model="laiyuan" style="margin-bottom: 0px;">
								<el-radio-button label="waimai">饿了么</el-radio-button>
								<el-radio-button label="lingshou">饿了么零售</el-radio-button>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="评价日期">
							<el-radio-group @input="onchangeoption" size="small" v-model="riqi" style="margin-bottom: 0px;">
								<el-radio-button label="qi">近 7 天</el-radio-button>
								<el-radio-button label="sanshi">近 30 天</el-radio-button>
								<el-radio-button label="liushi">近 60 天</el-radio-button>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="评价情况">
							<el-radio-group @input="onchangeoption" size="small" v-model="pjqk" style="margin-bottom: 0px;">
								<el-radio-button label="all">全部</el-radio-button>
								<el-radio-button label="you">有内容</el-radio-button>
								<el-radio-button label="wu">无内容</el-radio-button>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="回复情况">
							<el-radio-group @input="onchangeoption" size="small" v-model="hfqk" style="margin-bottom: 0px;">
								<el-radio-button label="all">全部</el-radio-button>
								<el-radio-button label="wei">未回复</el-radio-button>
								<el-radio-button label="yi">已回复</el-radio-button>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="评价类型">
							<el-radio-group @input="onchangeoption" size="small" v-model="pjlx" style="margin-bottom: 0px;">
								<el-radio-button label="all">全部</el-radio-button>
								<el-radio-button label="cha">差评（1-2星）</el-radio-button>
								<el-radio-button label="zhong">中评（3星）</el-radio-button>
								<el-radio-button label="hao">好评（4-5星）</el-radio-button>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="评价标签">
							<el-radio-group @input="onchangeoption" size="small" v-model="pjbq" style="margin-bottom: 0px;">
								<el-radio-button label="all">全部</el-radio-button>
								<el-radio-button label="youwu">有标签</el-radio-button>
								<el-radio-button label="right">无标签</el-radio-button>
							</el-radio-group>
						</el-form-item>
					</el-form>
				</div>
				<div style="margin-top: 20px; text-align: left; font-weight: 400; font-size: 18px;margin-left: 10px;">共
					0 相关评价</div>
				<div v-loading="loading" element-loading-text="加载中...">
					<el-empty :image-size="100" description="暂无订单"></el-empty>
				</div>
			</el-main>
		</el-container>
	</div>
</template>

<script>
	import axios from 'axios';
	import vm from "@/main";
	export default {
		data() {
			return {
				tabPosition: 'left',
				laiyuan: 'waimai',
				riqi: 'qi',
				pjqk: 'all',
				hfqk: 'all',
				pjlx: 'all',
				pjbq: 'all',
				lists: [],
				loading: false,
				shoplist: [],
				shopid:[],
				form: {
					name: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: ''
				}
			}

		},
		created() {
			this.getshoplist();
			this.getcomment();
			console.log("sss");
		},
		methods: {
			onchangeoption(val){
				console.log("changeoption",val)
				this.getcomment();
			},
			onchange(){
				this.getcomment();
			},
			getshoplist() {
				const formData1 = new FormData()
				formData1.append('act', "list")
				formData1.append('token', this.$Cookies.get("vue_elm_token"))
				const url = 'http://www.vssite.com/api/getShop.aspx'
				axios.post(url, formData1).then(data => {
					if (data.data.ret != 0) {
						console.log("sss")
					} else {
						this.shoplist = data.data.data;
					}
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
			},
			getcomment() {
				this.loading = true;
				const formData1 = new FormData()
				formData1.append('shopid', this.shopid)
				formData1.append('laiyuan', this.laiyuan)
				formData1.append('riqi', this.riqi)
				formData1.append('pjqk', this.pjqk)
				formData1.append('hfqk', this.hfqk)
				formData1.append('pjlx', this.pjlx)
				formData1.append('pjbq', "pjbq")
				formData1.append('token', this.$Cookies.get("vue_elm_token"))
				axios.post(this.$Apiurl + '/api/getOrderRatesByShopId.aspx', formData1)
					.then(response => {
						this.loading = false;
						console.log("response", response)
						if (response.data.body.errno == 0) {
							this.lists = response.data.body.data;
							console.log("this.lists", this.lists)
						} else {
							vm.$message({
								showClose: true,
								message: response.data.error.message,
								type: 'warning'
							});
							console.log("dsds")
						}
					})
					.catch(error => {
						console.error('There was an error!', error);
					});

				console.log("sdds");
			},
			onSubmit() {
				console.log('submit!');
			}
		}

	}
</script>

<style>
	.el-form-item {
		margin-bottom: 5px;
	}

	.review-header {
		top: 10px;
		bottom: 10px;
		display: block;
		padding: 0px;
	}

	.block-header {
		padding-bottom: 20px;
		border-bottom: 1px solid #F5F5F5;
		text-align: center;
	}
</style>
